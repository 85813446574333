import Vue from "vue";
import { domen } from "./constants";
import store from "@/store";

const axios = require("axios").create({
  baseURL: domen
});

const setBaseURL = ulr => {
  axios.defaults.baseURL = ulr;
};

const setHeader = token => {
  console.log(`Bearer ${token}`);

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const removeHeader = () => {
  axios.defaults.headers.common = {};
};

const showNotification = ({ type = "success", text = "Success!" }) => {
  Vue.prototype.$toasted.show(text, {
    theme: "toasted-primary",
    className: `custom-notification custom-notification_${type}`,
    position: "top-right",

    duration: 5000
  });
};

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // console.log(error);

    if (error.response.status == 401) {
      if (error.config.url.includes("/api/users/token/refresh/")) {
        store.dispatch("auth/logout");

        return;
      }

      return new Promise((resolve, reject) => {
        store.dispatch("auth/refreshToken", {
          cb: () => {
            console.log("refreshToken");

            resolve(
              axios({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data,
                params: error.config.params
              })
            );
          }
        });
      });
    }

    return Promise.reject(error);
  }
);

export { setHeader, removeHeader, setBaseURL, showNotification, axios };
