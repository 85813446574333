<template>
  <div class="form-group">
    <label v-if="label" for="select">{{ label }}</label>

    <!-- <p>{{ localValue}}</p> -->

    <select class="custom-select" v-model="localValue" id="select">
      <option
        v-for="(item, index) of options"
        :selected="index === 0"
        :value="item.value"
        :key="item.value"
      >{{ item.label }}</option>
    </select>
  </div>
</template>

<script>
import MixinVModel from "@/mixins/MixinVModel";

export default {
  name: "app-select",

  mixins: [MixinVModel],

  props: {
    options: {
      type: Array,
      default: () => []
    },

    label: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
</style>