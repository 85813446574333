// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import "element-ui/lib/theme-chalk/index.css";

import App from "./App";
import AppSlect from "./components/root/Select";
import CoreuiVue from "@coreui/vue";
import ElementUI from "element-ui";

import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
// import Loading from "vue-loading-overlay";
import Toasted from "vue-toasted";
import Vue from "vue";
import VueCookies from "vue-cookies";
import VCalendar from "v-calendar";
import vClickOutside from "v-click-outside";
import Vuex from "vuex";
import { axios } from "./utils/axios";
import { iconsSet as icons } from "./assets/icons/icons.js";
import router from "./router/index";
import store from "./store";

locale.use(lang);

// import "vue-loading-overlay/dist/vue-loading.css";

Vue.component("AppSlect", AppSlect);

// Init plugin
// Vue.use(Loading);
Vue.use(CoreuiVue);
Vue.use(VueCookies);
Vue.use(Toasted);
Vue.use(ElementUI);

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  // componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});

Vue.directive("click-outside", vClickOutside);

Vue.prototype.router = router;
Vue.prototype.axios = axios;

new Vue({
  el: "#app",
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: "<App/>",
  components: {
    App
  }
});
