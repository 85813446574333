import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import main from "./modules/main";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    main,
    auth
  },
  strict: debug
});
