import * as types from "../mutation-types";

// import axios from "axios";
import { axios, removeHeader, setHeader } from "../../utils/axios";

import Vue from "vue";

const state = () => ({
  is_authenticated: false,
  token: "",
  refreshToken: "",
  user: null,

  loading: false
});

const getters = {
  domen: ({ domen }) => domen,
  is_authenticated: ({ is_authenticated }) => is_authenticated,
  token: ({ token }) => token,
  refreshToken: ({ refreshToken }) => refreshToken,
  user: ({ user }) => user,
  loading: ({ loading }) => loading
};

const actions = {
  login(
    { commit, rootGetters, dispatch },
    { data, cb = () => {}, cbError = () => {} }
  ) {
    const url = `/api/users/token/`;

    // Start loading button in login form
    commit(types.SAVE_LOADING_STATE, true);

    axios
      .post(url, data)
      .then(response => {
        console.log(response.data);

        // // Save token
        commit(types.SAVE_USER_TOKEN, response.data.access);
        commit(types.SAVE_USER_REFRESH_TOKEN, response.data.refresh);

        setHeader(response.data.access);

        cb();

        commit(types.SAVE_LOADING_STATE, false);
      })
      .catch(error => {
        if (error.response.status === 401) {
          cbError(error.response.data.detail);
        } else if (error.response.status === 400) {
          if (error.response.data.recaptcha) {
            cbError("Confirm that you are not a robot.");
          }
        } else {
          cbError("Something went wrong.Try again!");
        }

        // Stoping loading button in registration form
        commit(types.SAVE_LOADING_STATE, false);
      });
  },

  refreshToken({ commit, rootGetters, dispatch }, { cb = () => {} }) {
    const url = `/api/users/token/refresh/`;

    let token = Vue.$cookies.get("user-refresh-token");

    console.log(url);
    console.log("refreshTokenrefreshTokenrefreshToken");

    axios
      .post(url, {
        refresh: token
      })
      .then(response => {
        commit(types.SAVE_USER_TOKEN, response.data.access);

        setHeader(response.data.access);

        if (Vue.prototype.router.history.current.name == "Login")
          Vue.prototype.router.push("/");

        cb();
      })
      .catch(error => {
        console.log(error);

        Vue.prototype.router.push({ name: "Login" });

        cb();
      });
  },

  logout({ commit, state, dispatch }) {
    commit(types.DELETE_USER_INFO);

    removeHeader();

    Vue.prototype.router.push({ name: "Login" });
  }
};

const mutations = {
  [types.SAVE_LOADING_STATE](state, loading) {
    state.loading = loading;
  },

  [types.SAVE_USER_TOKEN](state, token) {
    Vue.$cookies.set("user-token", token, "1d");

    state.token = token;
  },

  [types.SAVE_USER_REFRESH_TOKEN](state, token) {
    Vue.$cookies.set("user-refresh-token", token, "1d");

    state.refreshToken = token;
  },

  [types.DELETE_USER_INFO](state) {
    Vue.$cookies.remove("user-token");
    Vue.$cookies.remove("user-refresh-token");

    state.user = null;
    state.token = null;
    state.refreshToken = null;
    state.is_authenticated = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
