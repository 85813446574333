import Router from "vue-router";
import Vue from "vue";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Users
const Users = () => import("@/views/users/Users");
const User = () => import("@/views/users/User");

// Transactions
const Deposits = () => import("@/views/deposits/Deposits");

// Links
const Links = () => import("@/views/links/Links");

//Numbers
const Numbers = () => import("@/views/numbers/Numbers");

const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/users",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "users",
          meta: { label: "Users" },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              component: Users
            },
            {
              path: ":id",
              meta: { label: "User Details" },
              name: "User",
              component: User
            }
          ]
        },

        {
          path: "deposits",
          meta: { label: "Deposits" },
          component: Deposits
        },

        {
          path: "links",
          meta: { label: "Links" },
          component: Links
        },

        {
          path: "numbers",
          meta: { label: "Numbers" },
          component: Numbers
        }
      ]
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404
        },
        {
          path: "500",
          name: "Page500",
          component: Page500
        },
        {
          path: "login",
          name: "Login",
          component: Login
        }
      ]
    }
  ]
});
